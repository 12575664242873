<template>
    <div>
        <b-card>
            <b-card-header header-class="pt-0">
                <h4>تفاصيل قسم - {{ Detailschapter.name }}</h4>
                <b-badge
                    class="badge"
                    :variant="Detailschapter.isFree ? 'danger' : 'primary'"
                    >{{ Detailschapter.isFree ? "مدفوع" : "مجاني" }}</b-badge
                >
            </b-card-header>
            <b-card-body>
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col lg="6">
                            <ek-input-text
                                name="name"
                                readonly
                                label=" اسم الجامعة "
                                placeholder="ادخل اسم الجامعة"
                                v-model="Detailschapter.universityName"
                            >
                            </ek-input-text>
                            <b-row no-gutters>
                                <b-col lg="6" md="12">
                                    <ek-input-text
                                        readonly
                                        :options="years"
                                        v-model="Detailschapter.yearName"
                                        placeholder="السنة"
                                        name=" اختر السنة"
                                        label="السنة"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'السنة مطلوبة',
                                            },
                                        ]"
                                    >
                                    </ek-input-text>
                                </b-col>
                                <b-col lg="6" md="12" class="pl-2">
                                    <ek-input-text
                                        readonly
                                        :options="semesters"
                                        placeholder="اختر الفصل"
                                        name=" اختر الفصل"
                                        label="الفصل"
                                        v-model="Detailschapter.semesterName"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'الفصل مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-text>
                                </b-col>
                            </b-row>

                            <ek-input-text
                                readonly
                                type="number"
                                name="name"
                                label=" عدد الدروس "
                                placeholder="عدد الدروس"
                                v-model="Detailschapter.lessonCount"
                            >
                            </ek-input-text>
                        </b-col>

                        <b-col lg="6">
                            <ek-input-text
                                readonly
                                :rules="[
                                    {
                                        type: 'required',
                                        message: '  يرجى اختيار الكلية',
                                    },
                                ]"
                                name="classes"
                                v-model="Detailschapter.facultyName"
                                placeholder="تابع الكلية"
                                label="الكلية"
                                clearable
                            ></ek-input-text>
                            <b-row no-gutters>
                                <b-col lg="4" md="12" class="pl-2">
                                    <ek-input-text
                                        placeholder="اسم المادة"
                                        name=" اسم المادة"
                                        label="اسم المادة"
                                        v-model="Detailschapter.subjectName"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'اسم المادة مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-text>
                                </b-col>
                                <b-col lg="4" md="12" class="pl-2">
                                    <ek-input-select
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: '  استاذ القسم',
                                            },
                                        ]"
                                        :options="teachersNane"
                                        name="classes"
                                        v-model="Detailschapter.teacherId"
                                        placeholder=" ادخل استاذ القسم "
                                        label="استاذ القسم"
                                        clearable
                                    ></ek-input-select>
                                </b-col>
                                <b-col lg="4" md="12" class="pl-2">
                                    <ek-input-select
                                        :options="unitNames"
                                        placeholder="اسم الوحدة"
                                        name=" اسم الوحدة"
                                        label=" اسم الوحدة"
                                        v-model="Detailschapter.unitId"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'اسم الوحدة مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </b-card-body>
        </b-card>
        <div class="flex justify-content-between">
            <AddLessons class="ml-2"></AddLessons>
            <b-tabs pills v-model="tab" class="border-2">
                <b-tab title="الفيديوهات" active> </b-tab>
                <b-tab title="المستندات"> </b-tab>
            </b-tabs>
        </div>
        <ek-table
            :columns="tab == 0 ? VedioColumns : DucColumns"
            :items="Detailschapter.lessons"
            :no_select="true"
            :no_delete="true"
        >
            <template #table-header>
                <div class="d-flex p-1">
                    <unicon class="pr-1" fill="gray" name="bookmark"></unicon>
                    <h4>دروس القسم</h4>
                </div>
            </template>

            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";
import AddLessons from "@admin/lessons/components/AddLesson.vue";
export default {
    props: {
        id: String,
    },

    data: () => ({
        tab: 0,
        VedioColumns: [
            {
                label: "اسم الفيديو ",
                field: "name",
            },
            {
                label: "ترتيب الفيديو ",
                field: "lessonCount",
            },

            {
                label: "تاريخ الإضافة",
                field: "studentCount",
            },
            {
                label: "تحميل",
                field: "dateCreated",
            },
            {
                label: "تفاصيل",
                field: "details",
            },
        ],
        DucColumns: [
            {
                label: "اسم المستند ",
                field: "name",
            },
            {
                label: "ترتيب المستند ",
                field: "lessonCount",
            },

            {
                label: "تاريخ الإضافة",
                field: "studentCount",
            },
            {
                label: "تحميل",
                field: "dateCreated",
            },
            {
                label: "تفاصيل",
                field: "details",
            },
        ],
        filterDto: {
            semesterId: "",
            yearId: "",
            facultyId: "",
            universityId: "",
            pageSize: 4,
            pageIndex: 1,
            studentType: null,
        },
    }),
    components: {
        AddLessons,
    },
    computed: {
        ...mapState({
            Detailschapter: (state) => state.chapter.Detailschapter,
            universityNames: (state) => state.university.universityName,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
            unitNames: (state) => state.unit.unitNames,
            teachersNane: (state) => state.settings.teachersNane,
        }),
    },
    methods: {
        ...mapActions([
            "getchapterDetails",
            "updateSubject",
            "deleteSubject",
            "getNamesUniversity",
            "getSettingSemester",
            "getSettingYear",
            "addFileChapter",
            "getUnitNames",
            "getTeacherName",
        ]),
        fileWindowApi,
        onUpload(e) {
            if (e.file) {
                this.addFileChapter({ ...e, folderName: "Profile" }).then((res) => {
                    this.Detailschapter.imageUrl = res[0].data;
                    console.log(this.Detailschapter.imageUrl, res[0].data);
                });
            }
        },
    },
    // beforeDestroy() {
    //   this.$store.commit("Set_Subjects_Dto");
    // },
    created() {
        this.getchapterDetails(this.id);
        this.getNamesUniversity("0");
        this.getUnitNames();
        this.getSettingYear();
        this.getSettingSemester();
        this.getTeacherName();
    },
};
</script>
<style lang="scss">
.dropdown.b-dropdown {
    right: 0 !important;
}
.badge {
    position: absolute;
    top: -10px;
    right: -9px;
}
</style>
